<template>
  <div
    class="pl-[20px] w-full"
  >
    <div class="w-full h-full">
      <div
        v-if="titleName !== ''"
        class="text-main-1 uppercase w-full text-center flex alight-center h-full items-center"
      >
        <img
          class="h-full"
          src="@/static/img/arrow-right-double.svg"
          alt="arrow-right"
          width="21"
          height="14"
        />
        <span class="inline-block ml-3 text-sm text-ems-gray300 uppercase font-semibold text-[20px]">
          {{ titleName }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import VueTypes from 'vue-types';
import {useI18n} from "vue-i18n";
export default defineComponent({
  name: 'HeaderCustom',
  props: {
    titleName: VueTypes.string.def(''),
  },
  setup() {
    const { locale } = useI18n()
    return {
      locale
    }
  }
});
</script>
