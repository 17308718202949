<template>
  <div class="custom-tree">
    <template v-if="menu.children && menu.children.length > 0">
      <a-sub-menu :key="menu.path">
        <template v-slot:title>
          <div :title="menu.name">
            <InlineSvg
                v-if="menu.icon.startsWith('ems')"
                :src="`/assets/ems/${menu.icon}.svg`"
                :alt="menu.icon"
                height="18"
                width="18"
                class="fill-current text-ems-gray500 ems-icon flex-shrink-0"
            />
            <cds-feather-icons
                v-else
                :type="menu.icon"
                size="16"
                class="text-ems-gray500 flex-shrink-0"
            />
            <span>{{ menu.name }}</span>
          </div>
        </template>
        <tree-menu
          v-for="(child, i) in menu.children"
          :key="child.path + i"
          :menu="child"
        ></tree-menu>
      </a-sub-menu>
    </template>

    <template v-else>
      <a-menu-item @click="$emit('toggleCollapsed')" :key="menu.path" :title="menu.name" class="custom-tree">
        <router-link :to="!menu.id ? '/' : menu.path">
          <InlineSvg
            v-if="menu.icon.startsWith('ems')"
            :src="`/assets/ems/${menu.icon}.svg`"
            :alt="menu.icon"
            height="18"
            width="18"
            class="fill-current text-ems-gray500 ems-icon flex-shrink-0"
          />
          <cds-feather-icons
            v-else
            :type="menu.icon"
            size="16"
            class="text-ems-gray500 flex-shrink-0"
          />
          <span :title="menu.name">{{ menu.name }}</span>
        </router-link>
      </a-menu-item>
    </template>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import VueTypes from 'vue-types';
import InlineSvg from 'vue-inline-svg';

export default defineComponent({
  name: 'TreeMenu',
  components: { InlineSvg },
  props: {
    menu: VueTypes.object,
  },
  emits: ['toggleCollapsed'],
});
</script>

<style lang="scss">

.custom-tree {
  .ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    background-color: #5B5B9F;
  }
  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #5B5B9F;
  }
  .ant-menu-submenu-title {
    background-color: var(--Gray-800---262626---181824, #181824);
  }
  li.ant-menu-submenu.ant-menu-submenu-inline {
    background-color: var(--Gray-800---262626---181824, #181824);
  }
  .ant-menu-item.ant-menu-item-only-child:hover {
    background-color: var(--Gray-800---262626---181824, var(--Gray-700---434343---2B2A3A, #2B2A3A)) !important;
  }
  .ant-menu-submenu.ant-menu-submenu-inline:hover {
    background-color: var(--Gray-800---262626---181824, var(--Gray-700---434343---2B2A3A, #2B2A3A)) !important;
  }
  li.ant-menu-item.ant-menu-item-only-child {
    background-color: var(--Gray-800---262626---181824, #181824);
  }
}

</style>
